<template>
  <div>
    <titleBar
      v-show="!isInboxHome"
      :pitable="true"
      :inline="true"
      :title="titleBarTitle"
      :img="titleBarAvatar"
      :headerTitleLink="isInboxHome ? null : bot?.url"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="far"
      :actions="mobileActions"
    />

    <div class="section" :class="{ isInboxHome: isInboxHome, notHome: !isInboxHome }" v-show="!loadingList">
      <div class="container w1200" :class="debugCss ? 'debug' : ''">
        <div class="columns" style="height: 80vh">
          <div class="column is-narrow zone list">
            <h2 class="title is-3" style="margin-top: 1em">Chats</h2>
            <div class="list-scroll">
              <!-- buefy switch 
              <b-field horizontal v-if="$store.main.getters.isAdmin">
                <b-switch v-model="debugCss" type="is-dark">
                  debugCss
                  <b-tooltip type="is-dark" multilined label="debugCss">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field> -->
              <inboxList class="inboxList" :interlocutors="interlocutors" style="" v-if="!loadingList" />
              <jsonDebug :data="interlocutors" label="interlocutors" />
            </div>
          </div>
          <div class="column zone convo">
            <div class="theThread" style="">
              <inboxThread
                :bot="bot"
                :convo="convo"
                :convos="convos"
                :newRoute="newRoute"
                :isHistory="isHistory"
                :tasks="tasks"
                @reloadMsg="reloadMsg"
                v-if="bot && !loadingList"
              ></inboxThread>

              <div v-if="!$route.params.bot && !bot">
                <br /><br />
                <empty
                  title="Select a chat or start a new conversation"
                  btSecondary="/explore-bots"
                  btLabel="Explore new AIs"
                />
              </div>

              <!--
              <jsonDebug :data="bot" label="bot" />
              <jsonDebug :data="convo" label="active convo" />
             
              <jsonDebug :data="convos" label="other convos" />
              <jsonDebug :data="tasks" label="tasks" />

               -->
            </div>
          </div>
        </div>
      </div>

      <!--
    <v-row no-gutters>
      <v-col v-if="!isMobile || isInboxHome" cols="3">
        <v-list>
          <v-list-item
            v-for="(interlocutor, index) in interlocutors"
            :key="index"
            :to="{ name: 'inbox-thread', params: { conversationId: interlocutor.conversationId } }"
          >
            <v-list-item-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ interlocutor.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ interlocutor.lastMessage }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col v-if="!isInboxHome || (isMobile && interlocutors.length === 0)" cols="12" sm="9">
        <router-view></router-view>
      </v-col>
    </v-row> -->
    </div>
  </div>
</template>

<script>
import JsonDebug from "@/components/e/jsonDebug.vue";
import inboxList from "@/components/inbox/InboxList.vue";
import inboxThread from "@/components/inbox/inboxThread.vue";

export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  name: "InboxLayout",
  components: {
    inboxList,
    inboxThread,
    //  JsonDebugt,
  },
  data() {
    return {
      debugCss: false,
      // featuredTags,
      interlocutors: [],
      convos: [],
      convo: null,
      tasks: [],
      bot: null,
      //keyFeatures,
      // projs: [],
      loading: true,
      loadingTasks: false,
      loadingList: true,
      // loadingOps: true,
    };
  },
  mounted() {
    //window.API.getFeaturedBots().then((data) => {
    this.loadingList = true;
    this.loadThread(this.$route.params.bot, this.$route.params.convo);
    window.API.me.getUserInterlocutors().then((data) => {
      this.interlocutors = data;

      this.loadingList = false;
      this.loading = true;

      // console.log("PROJJ!", proj, proj.id);
    });
  },
  watch: {
    $route(to, from) {
      this.loadThread(to.params.bot, to.params.convo);
    },
  },
  methods: {
    loadThread(bot, convo) {
      // make an API call to fetch thread data
      // and update the thread data in the component's data
      console.log("LOADING THREAD", bot, convo);
      if (!bot) return; //this is home inbox.

      /**/
      if (this.bot && this.bot.handle != bot) {
        //prevent bad state to show on mobile
        this.bot = null;
      }

      window.API.botProfile(bot).then((b) => {
        this.bot = b;
        //  this.loadingBots = false;
        // console.log("PROJJ!", proj, proj.id);
      });

      if (convo) {
        window.API.getUserBotConvo(bot, convo).then((b) => {
          this.convo = b;
          //  this.loadingBots = false;
          // console.log("PROJJ!", proj, proj.id);
        });
      } else {
        this.convo = null; //erase active one, like when we click new
      }

      //convos
      const userId = this.$store.main.getters.handle; //may not be there...
      window.API.getUserBotConvos(bot, userId).then((b) => {
        this.convos = b;
        //  this.loadingBots = false;
        // console.log("PROJJ!", proj, proj.id);
      });
      //tasks
      window.API.getBotTasks(bot).then((tasks) => {
        this.tasks = tasks;
        this.loadingTasks = false;
        // console.log("PROJJ!", proj, proj.id);
      });
    },
    reloadMsg() {
      //when user sends a message. Without the STORE.
      // alert(3);
      window.API.getUserBotConvo(this.$route.params.bot, this.$route.params.convo).then((b) => {
        this.convo = b;
        //   alert(4);
        // show toast buefy
        this.$buefy.toast.open({
          message: "Message receive+ reloaded!",
          type: "is-success",
          position: "is-bottom-right",
          duration: 2000,
        });
      });
    },
  },
  computed: {
    isInboxHome() {
      return this.$route.name === "inbox";
    },
    isHistory() {
      return this.$route.name === "inboxHistory";
    },
    isInboxAuto() {
      return this.$route.name === "inboxAuto";
    },
    isNewMessage() {
      return this.$route.name === "inboxNewConvo" || this.$route.name === "inboxNewConvoTask";
    },
    interlocutorsShown() {
      //filter and add some props like "active., typing, etc..."
      return this.interlocutors;
      // Return a list of interlocutors here based on some state or props
    },

    newRoute() {
      return this.isNewMessage;
    },

    isMobile() {
      return true;
      // Return a boolean indicating whether the user is on a mobile device
    },
    titleBarTitle() {
      if (this.isInboxHome) return "Chats";
      if (this.isHistory) return "History";
      if (this.isNewMessage) return this.$route.params.bot + " ✨ ";
      if (this.bot) return this.bot.handle;
      var rb = this.$route.params.bot;
      if (rb) return rb;
      return ""; //prevent flickering, but let user go back while slow load.
    },
    titleBarAvatar() {
      //:img="titleBarAvatar"
      if (this.bot) return this.bot.avatar;
      // var rb = this.$route.params.bot;
      //   if (rb) return rb;
      return ""; //prevent flickering, but let user go back while slow load.
    },
    mobileActions: function () {
      var handle = this.$route.params.bot;
      if (this.isInboxHome) {
        return [];
      }
      return [
        // new

        {
          icon: "plus",
          label: "new",
          to: "/messages/" + handle + "/new",
          replace: true,
          // Router replace?
          hidden: this.isNewMessage || !this.hasHistory || this.isInboxAuto,
        },
        {
          icon: "history",
          label: "history",

          // ToGLLE HisTORY
          hidden: this.isHistory || !this.hasHistory,
          to: "/messages/" + handle + "/history",
          //replace: true,
        },

        {
          icon: "link",
          label: "Share",
          action: () => {
            //  alert("Share");
            navigator.share({
              title: "Web Share API Demo",
              text: "Check out Web Share API!",
              url: "https://web.dev/web-share/",
            });
          },
        },
        {
          icon: "cog",
          label: "Edit",
          to: "/bots/" + handle + "",
          hidden: !this.isOwner,
        },
        /*
        {
          icon: "user",
          label: "profile",
          to: "/" + handle + "",
        },*/
      ];
    },
    isOwner() {
      var currentUser = this.$store.main.state.userId;
      if (!currentUser) return false;
      if (!this.bot) return false;
      if (!this.bot.owner) return false;
      return this.bot?.owner?.includes(currentUser);
    },
    title() {
      return this.titleBarTitle + " / DM";
    },

    hasHistory() {
      //duplicated from the thread just for the titlebar action toggle...
      if (this.isInboxHome) return false;
      return this.convos?.length >= 1;
    },
  },
};
</script>

<style scoped>
.debug .theThread {
  border: 1px solid red;
}

.debug .list-scroll {
  border: 1px solid red;
}

.theThread {
  min-height: 100%;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.zone.convo {
  display: flex;
}
.section {
  padding-top: 0;
  padding-bottom: 0;
}

.list-scroll {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  max-height: 100%;

  position: relative;
}
.zone {
  /*
  border-radius: 50px;
  background-color: white;

  border: 1px #ccc solid;*/
  padding: 0;
}
/* on destop bigger padding */
@media only screen and (min-width: 1022px) {
  .zone {
    padding: 20px;
  }
}

.zone.list {
  border-radius: 50px;
  background-color: white;
}

/* mobiel selective columns depending on class isInboxHome using media query*/

.inboxList {
  max-width: 300px;
}
@media only screen and (max-width: 1022px) {
  .isInboxHome .zone.convo {
    display: none;
  }
  .notHome .zone.list {
    display: none;
  }
  .inboxList {
    max-width: 999px;
  }
}
</style>
